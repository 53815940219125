import { createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import rootReducer from "./features";
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["userData", "categoryData", "categoryItem", "addressData"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);
export const persistor = persistStore(store);
export default store;
