import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SETSELECTEDSTORE } from "../../redux/features/store";
import marketOpenOrNot from "../../utils/Market-Open-Close";
import TabSelectionMenu from "./TabSelectionMenu";

export default function KitchenCard({
  kitchenName,
  kitchenDistance,
  kitchenAddress,
  kitchenStatus,
  store,
  className,
  kitchenDescription,
}: {
  kitchenName?: string;
  kitchenDistance?: string;
  kitchenAddress?: string;
  kitchenStatus?: any;
  store: any;
  className: string;
  kitchenDescription?: string;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleStore = (store: any) => {
    if (Object.keys(marketOpenOrNot(store?.store_available))?.length) {
      dispatch(SETSELECTEDSTORE(store));
      sessionStorage.setItem("store", JSON.stringify(store));
      navigate("/menu");
    }
  };
  return (
    <>
      <div className={`store-card  ${className}`}>
        <div className="groupRow">
          <div className="nameGroup">
            <div className="flexBox justifyBetween">
              <h4>{kitchenName}</h4>
              <p>
                {kitchenDistance} {kitchenDistance && t("Home.away")}
              </p>
            </div>
          </div>
          <p className="text-[#828282] textSm font-normal mb-1">
            {kitchenDescription || "Burgers, Fast Food, Sandwiches"}
          </p>
          <div className="flex  textSm font-normal gap-1 items-center mb-2">
            <i className="fa fa-star text-yellow-500"></i>
            <p>
              4.9 <span className="text-[#828282]">(2000 ratings)</span>
            </p>
          </div>
          <p className="flex  textSm font-normal gap-1 md:items-center mb-2 ">
            <img src="/images/location-icon.svg" className="size-5" />
            {kitchenAddress}
          </p>
          <div className="flex justify-between flex-wrap  gap-4">
            <div className="flex md:gap-2 gap-3 items-center">
              <button type="button" className="sky-dim-btn cursor-text">
                {Object.keys(marketOpenOrNot(store?.store_available))
                  ?.length !== 0
                  ? "Open "
                  : "Close"}
              </button>
              <div className="flex md:gap-2  flex-wrap">
                <p className="textSm font-bold text-[#181E27]">
                  {Object.keys(marketOpenOrNot(store?.store_available))
                    ?.length !== 0 &&
                    marketOpenOrNot(store?.store_available)?.from_time}{" "}
                  {Object.keys(marketOpenOrNot(store?.store_available))
                    .length !== 0 &&
                    ` -  ${marketOpenOrNot(store?.store_available)?.to_time}`}
                </p>
                <p className="textSm font-normal text-[#181E27]">
                  {t("Home.pickCol")}
                </p>
              </div>
            </div>
            <TabSelectionMenu />
            {/* {className === "home" ? (
              <button
                className="btn-secondary"
                onClick={() => handleStore(store)}
              >
                <Link to="/">
                  {Object.keys(marketOpenOrNot(kitchenStatus))?.length !== 0
                    ? `${t("Home.orderOnline")}`
                    : "Closed"}
                </Link>
              </button>
            ) : (
              <TabSelectionMenu />
            )} */}
          </div>
          {/* <div className="flex md:flex-row flex-col gap-2 md:mt-8 mt-4 justify-between">
            <div className="blockLeft">
              <p>{t("Home.pickCol")}</p>

              <p>
                <span
                  className={
                    Object.keys(marketOpenOrNot(kitchenStatus))?.length !== 0
                      ? "open"
                      : "close"
                  }
                >
                  {Object.keys(marketOpenOrNot(kitchenStatus))?.length !== 0
                    ? "Open• "
                    : "Close"}
                </span>
                <span className="time">
                  {Object.keys(marketOpenOrNot(kitchenStatus))?.length !== 0 &&
                    marketOpenOrNot(kitchenStatus)?.from_time}{" "}
                  {Object.keys(marketOpenOrNot(kitchenStatus)).length !== 0 &&
                    ` -  ${marketOpenOrNot(kitchenStatus)?.to_time}`}
                </span>
              </p>
            </div>
            <div
              className="blockRight btnBlock"
              onClick={() => handleStore(store)}
            >
              <LinkButton
                url="/"
                btnClass="btnBlue"
                buttonText={
                  Object.keys(marketOpenOrNot(kitchenStatus))?.length !== 0
                    ? `${t("Home.orderOnline")}`
                    : "Closed"
                }
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
