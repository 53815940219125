const MerchentServiceCharge = (price: number, store: any) => {
  return store?.domain_setting?.is_merchant == 1
    ? (price * parseFloat(store?.domain_setting?.mer_service_charge)) / 100 <
      parseFloat(store?.domain_setting?.mer_min_service_charge)
      ? store?.domain_setting?.mer_min_service_charge
      : (Number(price) *
          parseFloat(store?.domain_setting?.mer_service_charge)) /
          100 >
        parseFloat(store?.domain_setting?.mer_max_service_charge)
      ? store?.domain_setting?.mer_max_service_charge
      : (price * parseFloat(store?.domain_setting?.mer_service_charge)) / 100
    : 0;
};
export default MerchentServiceCharge;
