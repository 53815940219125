import { combineReducers } from "@reduxjs/toolkit";

import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage/session";

import allOrder from "./AllOrder";
import cartData from "./CartData";
import customizedData from "./CustomizedData";
import variationData from "./Variation";
import addressData from "./address";
import categoryItem from "./categoryItem";
import categoryData from "./categoryList";
import store from "./store";
import userData from "./userData";
const authPersistConfig = {
  key: "session",
  storage: storage,
  blacklist: ["somethingTemporary", "addressData"],
};
export const rootReducer: any = combineReducers({
  store,
  categoryData: persistReducer(authPersistConfig, categoryData),
  categoryItem: persistReducer(authPersistConfig, categoryItem),
  cartData,
  variationData,
  customizedData,
  userData: persistReducer(authPersistConfig, userData),
  addressData,
  allOrder,
});

export default rootReducer;
