import Modal from "react-modal";

const ChangeStoreModal = ({
  orderOnline,
  setOrderOnline,
  clearData,
  cancelChangeStore,
}: {
  orderOnline: boolean;
  setOrderOnline: Function;
  clearData: Function;
  cancelChangeStore: Function;
}) => {
  return (
    <Modal
      ariaHideApp={false}
      className="confirmation"
      isOpen={orderOnline}
      contentLabel=""
    >
      <div className="modalHeader">
        <div className="headerTitle">
          <h3 className="text-2xl">Change Store</h3>
        </div>
        <button className="close" onClick={() => setOrderOnline(!orderOnline)}>
          <svg
            id="Icons_Alerts_alarm"
            data-name="Icons/Alerts/alarm"
            xmlns="http://www.w3.org/2000/svg"
            width="21.037"
            height="21.04"
            viewBox="0 0 21.037 21.04"
          >
            <path
              id="close"
              d="M2.4.412A1.405,1.405,0,1,0,.412,2.4l8.117,8.117L.412,18.634A1.405,1.405,0,1,0,2.4,20.622l8.117-8.112,8.117,8.117a1.405,1.405,0,1,0,1.988-1.988L12.51,10.517,20.627,2.4A1.407,1.407,0,1,0,18.634.412L10.516,8.529Z"
              transform="translate(0 0.001)"
              fill="#fff"
            />
          </svg>
        </button>
      </div>

      <div className="modalBody">
        <div className="card card--block mb-6">
          <h3 className="modalTitle">
            Are you sure you want to change store? You will lose your current
            basket if you do.
          </h3>
        </div>
      </div>
      <div className="modalFooter mb-6">
        <div className="actionBtn flex justify-center gap-4">
          <button
            type="submit"
            className="btn btnBorderBlue"
            onClick={() => clearData()}
          >
            Yes
          </button>
          <button
            type="submit"
            className="btn btnBlue noRadius arrowBtn"
            onClick={() => cancelChangeStore()}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeStoreModal;
