import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useAllCharges from "../../hooks/AllCharge";
import { SETCARTDATA } from "../../redux/features/CartData";
import { SETCUSTOMIZEDDATA } from "../../redux/features/CustomizedData";
import { SETVARIATIONDATA } from "../../redux/features/Variation";
import checkItemType from "../../utils/checkItemType";
import DecrementItemData from "../../utils/DecrementItemData";
import duplicateItemData from "../../utils/DuplicateItemData";
import incrementItemData from "../../utils/Increment";
import ItemSizeName from "../../utils/ItemSizeName";
import ParseFloat from "../../utils/ParseFloat";
import CustomizedModal from "../Modal/Customized-Modal";
import PaymentModal from "../Modal/PaymentModal";
import VariationModal from "../Modal/Variation-Modal";

const CheckoutDetails = () => {
  const { t } = useTranslation();
  const [taxes, discount, service_charge, remaining_amount, totalPrice] =
    useAllCharges();
  const {
    variationData,
    customizedData,
    cartData: { items, itemCount, deliveryCharge },
    store: { selectedType, selectedStore },
    addressData: { selectedAddress },
  }: any = useSelector((state: any) => state);
  const [open, setOpen] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const disPatch = useDispatch();
  console.log(selectedStore);
  const handleEditItem = (data: any) => {
    if (
      data?.item_variation?.length > 0 &&
      data?.variation_status != 0 &&
      data?.customize_status == 0
    ) {
      data["isEdit"] = true;
      disPatch(SETVARIATIONDATA(data));
    } else if (data?.customize_status === 0) {
    } else {
      data["isEdit"] = true;
      disPatch(SETCUSTOMIZEDDATA(data));
    }
    setOpen(true);
  };

  const onCloseModal = () => setOpen(false);
  const [isNote, setIsNote] = useState(false);
  const [notes, setNotes] = useState({ text: "", id: "", uniqueId: "" });

  const onClosePaymentModal = () => setOpenPayment(false);
  const notesOpen = (item: any) => {
    if (item.item_id == notes.id) {
      setNotes({
        ...notes,
        id: item.item_id,
        uniqueId: item.uniqueIndex,
      });
      setIsNote(!isNote);
    } else {
      setNotes({
        ...notes,
        text: item.notes || "",
        id: item.item_id,
        uniqueId: item.uniqueIndex,
      });
      setIsNote(true);
    }
  };
  function addNotes() {
    const ui = items.find((e: any) => e.uniqueIndex === notes.uniqueId);
    const index = items.indexOf(ui);
    items.splice(index, 1, { ...ui, notes: notes.text });
    disPatch(SETCARTDATA(items));
    setIsNote(false);
    setNotes({ text: "", id: "", uniqueId: "" });
  }
  console.log("deliveryCharge", deliveryCharge, selectedAddress);
  return (
    <div className="store-card">
      <button
        className="btn-secondary w-full hidden md:block"
        // onClick={() => setOpenPayment(true)}
        type="submit"
        // type="button"
      >
        <span className="textMd font-normal mr-2">Confirm & Pay</span>{" "}
        {ParseFloat(totalPrice || 0)}
      </button>
      <div className="cartData !px-0">
        <div className="cartDataHeader">
          <div className="flexBox justifyBetween">
            <div className="cartItem !textMd">{t("CartDetails.items")}</div>
            <div className="cartItem !textMd ">{t("CartDetails.price")}</div>
          </div>
        </div>
        <div className="variationWrapper itemDetail !pb-0 !h-auto">
          {items?.map((item: any) => {
            return (
              <div className="repeatItem">
                <div className="itemName flexBox justifyBetween">
                  <div className="myCart flexBox gap-2 items-center">
                    {/* <div
                      className={`sortType mb--0 ${
                        item?.item_type == 0 ? "Veg" : "nonVeg"
                      }`}
                    >
                      <div className="circleSize"></div>
                    </div> */}
                    <p className="textMd font-bold text-[#151E3F] ">
                      {item?.name}
                      {ItemSizeName(item)}
                    </p>
                  </div>
                  <p className="textMd font-bold text-[#000] ">
                    {" "}
                    {ParseFloat(item?.price * (item?.quantity || 1))}
                  </p>
                </div>
                <div className="itemSize">
                  {/* step option .... */}

                  {item?.selectedoptions?.map((step: any) => {
                    return (
                      <div className="flexBox justifyBetween">
                        <div className="textMd text-[#676767]">
                          <span className="flex gap-2 items-center">
                            {" "}
                            <i className="fa fa-circle text-[4px]"></i>{" "}
                            {step?.title}
                          </span>
                        </div>
                        <div className="textMd text-[#000]">
                          {step?.web_price
                            ? ParseFloat(step?.web_price)
                            : "Free"}
                        </div>
                      </div>
                    );
                  })}

                  {/* category ... */}
                  {item?.selectedCategory?.map((step: any) => {
                    return (
                      <div className="flexBox justifyBetween">
                        <div className="textMd text-[#676767]">
                          <span className="flex gap-2 items-center">
                            <i className="fa fa-circle text-[4px]"></i>{" "}
                            {step?.mapNewItem?.name}
                          </span>
                        </div>
                        <div className="textMd text-[#000]">
                          {step?.mapNewItem?.web_price
                            ? ParseFloat(step?.mapNewItem?.web_price)
                            : "Free"}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="allIcons 2 ml-auto">
                  <div className="flex gap-2 justify-between">
                    {checkItemType(item) != 3 && (
                      <div
                        className="iconBox"
                        onClick={() => handleEditItem(item)}
                      >
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 25 25"
                          fill="currentColor"
                        >
                          <path d="M24.1975 7.54844C25.0882 6.65775 25.0882 5.20452 24.1975 4.36072L21.3848 1.54802C20.541 0.657333 19.0877 0.657333 18.1971 1.54802L16.0407 3.70442C15.8531 3.89193 15.8531 4.26696 16.0407 4.50135L21.2441 9.70484C21.4785 9.89235 21.8536 9.89235 22.0411 9.70484L24.1975 7.54844ZM14.2124 5.53267L1.88342 17.8617L0.898974 23.5339C0.758339 24.3309 1.41463 24.9872 2.21156 24.8465L7.88383 23.8621L20.2128 11.5331C20.4472 11.3456 20.4472 10.9705 20.2128 10.7362L15.0093 5.53267C14.7749 5.34516 14.3999 5.34516 14.2124 5.53267ZM5.02426 20.7212H7.27442V22.4557L4.22733 22.9714L2.7741 21.5182L3.28976 18.4711H5.02426V20.7212Z"></path>
                        </svg>
                      </div>
                    )}
                    {checkItemType(item) == 3 && item.quantity == 1 && (
                      <div
                        onClick={() =>
                          DecrementItemData(
                            item,
                            items,
                            disPatch,
                            itemCount,
                            item
                          )
                        }
                        className="iconBox"
                      >
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 44 50"
                          fill="currentColor"
                        >
                          <path d="M28.9062 42.1875H30.4688C30.8594 42.1875 31.25 41.8945 31.25 41.4062V14.8438C31.25 14.4531 30.8594 14.0625 30.4688 14.0625H28.9062C28.418 14.0625 28.125 14.4531 28.125 14.8438V41.4062C28.125 41.8945 28.418 42.1875 28.9062 42.1875ZM13.2812 42.1875H14.8438C15.2344 42.1875 15.625 41.8945 15.625 41.4062V14.8438C15.625 14.4531 15.2344 14.0625 14.8438 14.0625H13.2812C12.793 14.0625 12.5 14.4531 12.5 14.8438V41.4062C12.5 41.8945 12.793 42.1875 13.2812 42.1875ZM42.9688 6.25H32.8125L29.4922 1.95312C28.7109 0.878906 27.0508 0 25.7812 0H17.9688C16.6016 0 14.9414 0.878906 14.1602 1.95312L10.9375 6.25H0.78125C0.292969 6.25 0 6.64062 0 7.03125V8.59375C0 9.08203 0.292969 9.375 0.78125 9.375H3.125V45.3125C3.125 47.9492 5.17578 50 7.8125 50H35.9375C38.4766 50 40.625 47.9492 40.625 45.3125V9.375H42.9688C43.3594 9.375 43.75 9.08203 43.75 8.59375V7.03125C43.75 6.64062 43.3594 6.25 42.9688 6.25ZM16.6992 3.80859C16.8945 3.41797 17.4805 3.22266 17.9688 3.125H25.7812C26.1719 3.22266 26.7578 3.41797 26.9531 3.80859L28.9062 6.25H14.8438L16.6992 3.80859ZM37.5 45.3125C37.5 46.1914 36.7188 46.875 35.9375 46.875H7.8125C6.93359 46.875 6.25 46.1914 6.25 45.3125V9.375H37.5V45.3125ZM21.0938 42.1875H22.6562C23.0469 42.1875 23.4375 41.8945 23.4375 41.4062V14.8438C23.4375 14.4531 23.0469 14.0625 22.6562 14.0625H21.0938C20.6055 14.0625 20.3125 14.4531 20.3125 14.8438V41.4062C20.3125 41.8945 20.6055 42.1875 21.0938 42.1875Z"></path>
                        </svg>
                      </div>
                    )}
                    {checkItemType(item) == 3 && item.quantity > 1 && (
                      <div
                        onClick={() =>
                          DecrementItemData(
                            item,
                            items,
                            disPatch,
                            itemCount,
                            item
                          )
                        }
                        className="iconBox decreaseNo"
                      >
                        <span className="">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 38 5"
                            fill="currentColor"
                          >
                            <path d="M36.7188 0H0.78125C0.292969 0 0 0.390625 0 0.78125V3.90625C0 4.39453 0.292969 4.6875 0.78125 4.6875H36.7188C37.1094 4.6875 37.5 4.39453 37.5 3.90625V0.78125C37.5 0.390625 37.1094 0 36.7188 0Z"></path>
                          </svg>
                        </span>
                      </div>
                    )}

                    {checkItemType(item) == 3 ? (
                      <div className="iconBox Quantity">
                        <p className="font-semibold">{item.quantity}</p>
                      </div>
                    ) : (
                      <div
                        onClick={() =>
                          DecrementItemData(
                            item,
                            items,
                            disPatch,
                            itemCount,
                            item
                          )
                        }
                        className="iconBox"
                      >
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 44 50"
                          fill="currentColor"
                        >
                          <path d="M28.9062 42.1875H30.4688C30.8594 42.1875 31.25 41.8945 31.25 41.4062V14.8438C31.25 14.4531 30.8594 14.0625 30.4688 14.0625H28.9062C28.418 14.0625 28.125 14.4531 28.125 14.8438V41.4062C28.125 41.8945 28.418 42.1875 28.9062 42.1875ZM13.2812 42.1875H14.8438C15.2344 42.1875 15.625 41.8945 15.625 41.4062V14.8438C15.625 14.4531 15.2344 14.0625 14.8438 14.0625H13.2812C12.793 14.0625 12.5 14.4531 12.5 14.8438V41.4062C12.5 41.8945 12.793 42.1875 13.2812 42.1875ZM42.9688 6.25H32.8125L29.4922 1.95312C28.7109 0.878906 27.0508 0 25.7812 0H17.9688C16.6016 0 14.9414 0.878906 14.1602 1.95312L10.9375 6.25H0.78125C0.292969 6.25 0 6.64062 0 7.03125V8.59375C0 9.08203 0.292969 9.375 0.78125 9.375H3.125V45.3125C3.125 47.9492 5.17578 50 7.8125 50H35.9375C38.4766 50 40.625 47.9492 40.625 45.3125V9.375H42.9688C43.3594 9.375 43.75 9.08203 43.75 8.59375V7.03125C43.75 6.64062 43.3594 6.25 42.9688 6.25ZM16.6992 3.80859C16.8945 3.41797 17.4805 3.22266 17.9688 3.125H25.7812C26.1719 3.22266 26.7578 3.41797 26.9531 3.80859L28.9062 6.25H14.8438L16.6992 3.80859ZM37.5 45.3125C37.5 46.1914 36.7188 46.875 35.9375 46.875H7.8125C6.93359 46.875 6.25 46.1914 6.25 45.3125V9.375H37.5V45.3125ZM21.0938 42.1875H22.6562C23.0469 42.1875 23.4375 41.8945 23.4375 41.4062V14.8438C23.4375 14.4531 23.0469 14.0625 22.6562 14.0625H21.0938C20.6055 14.0625 20.3125 14.4531 20.3125 14.8438V41.4062C20.3125 41.8945 20.6055 42.1875 21.0938 42.1875Z"></path>
                        </svg>
                      </div>
                    )}

                    {checkItemType(item) == 3 && (
                      <div
                        onClick={() =>
                          incrementItemData(
                            item,
                            items,
                            disPatch,
                            itemCount,
                            item
                          )
                        }
                        className="iconBox increaseNo"
                      >
                        <span className="">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 38 38"
                            fill="currentColor"
                          >
                            <path d="M36.7188 16.4062H21.0938V0.78125C21.0938 0.390625 20.7031 0 20.3125 0H17.1875C16.6992 0 16.4062 0.390625 16.4062 0.78125V16.4062H0.78125C0.292969 16.4062 0 16.7969 0 17.1875V20.3125C0 20.8008 0.292969 21.0938 0.78125 21.0938H16.4062V36.7188C16.4062 37.207 16.6992 37.5 17.1875 37.5H20.3125C20.7031 37.5 21.0938 37.207 21.0938 36.7188V21.0938H36.7188C37.1094 21.0938 37.5 20.8008 37.5 20.3125V17.1875C37.5 16.7969 37.1094 16.4062 36.7188 16.4062Z"></path>
                          </svg>
                        </span>
                      </div>
                    )}
                    <div className="iconBox" onClick={() => notesOpen(item)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M4.96654 2.97668C3.98146 2.97668 3.18359 3.77455 3.18359 4.75963V17.2402C3.18359 18.2253 3.98146 19.0232 4.96654 19.0232H13.8813L19.2301 13.6744V4.75963C19.2301 3.77455 18.4322 2.97668 17.4472 2.97668H4.96654ZM4.96654 4.75963H17.4472V12.7829H12.9898V17.2402H4.96654V4.75963ZM6.74949 6.54258V8.32552H15.6642V6.54258H6.74949ZM6.74949 10.1085V11.8914H11.2068V10.1085H6.74949Z"
                          fill="#151E3F"
                        />
                      </svg>
                    </div>
                    {checkItemType(item) != 3 && (
                      <div
                        onClick={() =>
                          duplicateItemData(item, items, disPatch, itemCount)
                        }
                        className="iconBox"
                      >
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 23 22"
                          fill="none"
                        >
                          <path
                            d="M4.19164 2.08527C3.20656 2.08527 2.40869 2.88313 2.40869 3.86821V16.3488H4.19164V3.86821H16.6723V2.08527H4.19164ZM7.75753 5.65116C6.77245 5.65116 5.97458 6.44903 5.97458 7.4341V18.1318C5.97458 19.1169 6.77245 19.9147 7.75753 19.9147H18.4552C19.4403 19.9147 20.2381 19.1169 20.2381 18.1318V7.4341C20.2381 6.44903 19.4403 5.65116 18.4552 5.65116H7.75753ZM7.75753 7.4341H18.4552V18.1318H7.75753V7.4341Z"
                            fill="#151E3F"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
                {item.notes ? (
                  <div className="mt-3  ">
                    <span>
                      <b>notes:- </b>
                      {item.notes || ""}{" "}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {isNote &&
                  notes.id == item.item_id &&
                  notes.uniqueId == item?.uniqueIndex && (
                    <div className="mt-3  ">
                      <textarea
                        rows={2}
                        onChange={(e) =>
                          setNotes({ ...notes, text: e.target.value })
                        }
                        value={notes.text}
                        className="border border-[#C5C5C5] textMd p-2 rounded-md w-full"
                        placeholder="Add Notes"
                      />
                      <div className="flex justify-end">
                        <button
                          type="button"
                          onClick={addNotes}
                          className="bg-[#1466FA1A] rounded-md textSm textBlue px-3 py-1 font-semibold"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
              </div>
            );
          })}
          <hr />
          <div className="flexBox justifyBetween mt-3">
            <div className="textMd text-[#000] font-bold">Sub Total</div>
            <div className="textMd text-[#000] font-bold">
              {ParseFloat(taxes?.sub_total)}
            </div>
          </div>
          {taxes?.tax?.map((_: any) => {
            return (
              <div className="flexBox justifyBetween mt">
                <div className="textMd text-[#676767]">{_?.tax_name}</div>
                <div className="textMd text-[#000]">
                  {ParseFloat(_?.actual_tax_amount)}
                </div>
              </div>
            );
          })}
          {discount ? (
            <div className="flexBox justifyBetween mt">
              <div className="textMd  text-green-500">Discount</div>
              <div className="textMd  text-green-500">
                - {ParseFloat(discount)}
              </div>
            </div>
          ) : (
            ""
          )}
          {service_charge ? (
            <div className="flexBox justifyBetween mt">
              <div className="textMd text-[#676767]">Service Fee</div>
              <div className="textMd text-[#000]">
                {ParseFloat(service_charge)}
              </div>
            </div>
          ) : (
            ""
          )}
          {deliveryCharge ? (
            <div className="flexBox justifyBetween mt">
              <div className="textMd text-[#676767]">Delivery Charge</div>
              <div className="textMd text-[#000]">
                {ParseFloat(deliveryCharge)}
              </div>
            </div>
          ) : (
            ""
          )}
          {remaining_amount ? (
            <div className="flexBox justifyBetween mt">
              <div className="textMd text-[#676767]">Remaining Amount</div>
              <div className="textMd text-[#000]">
                {ParseFloat(remaining_amount)}
              </div>
            </div>
          ) : (
            ""
          )}
          <hr className="mt-3" />
          <div className="flexBox justifyBetween mt-3">
            <div className="textMd text-[#000] font-bold">
              {t("CartDetails.total")}
            </div>
            <div className="textMd text-[#000] font-bold">
              {ParseFloat(totalPrice || 0)}
            </div>
          </div>
        </div>
      </div>
      {/* variation .... */}
      {open && variationData && Object.keys(variationData).length > 0 && (
        <VariationModal
          menu_id={variationData?.menu_id}
          taxdata={variationData}
          open={open}
          onCloseModal={onCloseModal}
        />
      )}
      {/* customized ..... */}
      {open && customizedData && Object.keys(customizedData).length > 0 && (
        <CustomizedModal
          menu_id={customizedData?.menu_id}
          taxdata={customizedData}
          open={open}
          onCloseModal={onCloseModal}
        />
      )}

      {openPayment ? (
        <PaymentModal
          openPayment={openPayment}
          onClosePaymentModal={onClosePaymentModal}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default CheckoutDetails;
