import ConvertUkTimeZone from "./convert-time-zone";

const marketOpenOrNot = (data: any) => {
  if (!data) {
    return {};
  } else if (data.length > 0) {
    const day = data?.find(
      (item: any) =>
        item?.available_days === ConvertUkTimeZone().format("dddd") &&
        item?.status === "open"
    )
      ? [
          data?.find(
            (item: any) =>
              item?.available_days === ConvertUkTimeZone().format("dddd") &&
              item?.status === "open"
          ),
        ]
      : [];

    if (day.length > 0) {
      let startMinutes = 0;
      let startHour = 0;
      let endHour = 0;
      let endMinutes = 0;

      for (let i = 0; i < day[0]?.availability?.length; i++) {
        if (
          day[0]?.availability[i]?.from_time?.split(" ")[1] === "AM" &&
          day[0]?.availability[i]?.from_time?.split(":")[0] === "12"
        ) {
          startHour = 0;
          let temp = day[0]?.availability[i]?.from_time?.split(":")[1];
          startMinutes = temp?.split(" ")[0];
        } else if (
          day[0]?.availability[i]?.from_time?.split(" ")[1] === "PM" &&
          day[0]?.availability[i]?.from_time?.split(":")[0] !== "12"
        ) {
          startHour =
            Number(day[0]?.availability[i]?.from_time?.split(":")[0]) + 12;
          let temp = day[0]?.availability[i]?.from_time?.split(":")[1];

          startMinutes = temp?.split(" ")[0];
        } else {
          startHour = day[0]?.availability[i]?.from_time?.split(":")[0];
          let temp = day[0]?.availability[i]?.from_time?.split(":")[1];

          startMinutes = temp?.split(" ")[0];
        }

        if (
          day[0]?.availability[i]?.to_time?.split(" ")[1] === "AM" &&
          day[0]?.availability[i]?.to_time?.split(":")[0] === "12"
        ) {
          endHour = 0;
          let temp = day[0]?.availability[i]?.to_time?.split(":")[1];

          endMinutes = temp?.split(" ")[0];
        } else if (
          day[0]?.availability[i]?.to_time?.split(" ")[1] === "PM" &&
          day[0]?.availability[i]?.to_time?.split(":")[0] !== "12"
        ) {
          endHour =
            Number(day[0]?.availability[i]?.to_time?.split(":")[0]) + 12;
          let temp = day[0]?.availability[i]?.to_time?.split(":")[1];

          endMinutes = temp?.split(" ")[0];
        } else {
          endHour = day[0]?.availability[i]?.to_time?.split(":")[0];
          let temp = day[0]?.availability[i]?.to_time?.split(":")[1];

          endMinutes = temp?.split(" ")[0];
        }

        if (
          ConvertUkTimeZone() <=
            ConvertUkTimeZone().set({
              hour: endHour,
              minute: endMinutes,
              second: 0,
            }) &&
          ConvertUkTimeZone() >=
            ConvertUkTimeZone().set({
              hour: startHour,
              minute: startMinutes,
              second: 0,
            })
        ) {
          let getSeconds = day[0]?.availability[i]?.to_time
            ?.split(":")[2]
            ?.split(" ");
          const clone = { ...day[0]?.availability[i] };
          //  structuredClone(day[0]?.availability[i]);
          if (getSeconds?.length > 1) {
            clone.to_time = `12:00 AM`;
          }
          return clone;
        }
      }

      return {};
    } else {
      return {};
    }
  } else {
    return {};
  }
};

export default marketOpenOrNot;
