import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CLEARCART } from "../../redux/features/CartData";
import { CLEARCUSTOMIZEDDATA } from "../../redux/features/CustomizedData";
import { CLEARVARIATIONDATA } from "../../redux/features/Variation";
import { SETCATEGORYITEM } from "../../redux/features/categoryItem";
import { SETSELECTEDSTORE } from "../../redux/features/store";
import ChangeStoreModal from "../Modal/ChangeStore";
export default function Logo() {
  const [isClear, setIsClear] = useState(false);
  const navigation = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { itemCount } = useSelector((state: any) => state.cartData);
  const { domain_setting } = useSelector((state: any) => state.store);
  const ChangeStore = () => {
    //(setStore)
    if (pathname != "/menu" && pathname != "/") {
      navigation("/menu");
    } else if (itemCount > 0) {
      setIsClear(!isClear);
    } else {
      sessionStorage.removeItem("storeId");
      sessionStorage.removeItem("store_url");
      sessionStorage.removeItem("allStores");
      dispatch(CLEARVARIATIONDATA({}));
      dispatch(CLEARCUSTOMIZEDDATA({}));
      dispatch(SETSELECTEDSTORE({}));
      dispatch(SETCATEGORYITEM([]));
      dispatch(CLEARCART());

      navigation("/");
    }
  };
  const clearData = async () => {
    sessionStorage.removeItem("storeId");
    sessionStorage.removeItem("store_url");
    sessionStorage.removeItem("allStores");
    dispatch(CLEARVARIATIONDATA({}));
    dispatch(CLEARCUSTOMIZEDDATA({}));
    dispatch(SETSELECTEDSTORE({}));
    dispatch(SETCATEGORYITEM([]));
    dispatch(CLEARCART());

    sessionStorage.removeItem("items");
    navigation("/");
  };
  const cancelChangeStore = () => {
    setIsClear(!isClear);
  };
  return (
    <>
      <div className="md:min-w-36 min-w-10 h-10" onClick={ChangeStore}>
        {domain_setting?.domain_image ? (
          <img
            src={`${domain_setting?.domain_image?.base_url}/${domain_setting?.domain_image?.attachment_url}`}
            alt="Logo"
            className="w-auto h-full"
          />
        ) : (
          <img src="/logo.png" width="130" height="41" alt="Logo" />
        )}
      </div>

      {isClear && (
        <ChangeStoreModal
          orderOnline={isClear}
          setOrderOnline={setIsClear}
          cancelChangeStore={cancelChangeStore}
          clearData={clearData}
        />
      )}
    </>
  );
}
